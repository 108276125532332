<template>
  <button class="social-button" @click="onClick">
    <img :src="imageSrc" alt="icon" />
    <span v-if="buttonText" class="btn-txt">{{buttonText}}</span>
  </button>
</template>

<script>

export default {
    name: 'SocialButton',
    computed: {
        imageSrc() {
            return require (`@/assets/images/icons/${this.type}.svg`);
        }
    },
    props: {
        type: String,
        link: {
            type: String,
            default: window.location.href
        },
        buttonText:{
          type: String,
        },
        text: {
          type: String,
        }
    },
    methods: {
      onClick() {                
        let url = '';

        switch (this.type) {
          case 'facebook':
            url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.link)}&quote=${encodeURIComponent(this.text)}`;
            window.open(url, this.type, 'width=550,height450,scrollbars=yes,toolbar=no,location=yes');
            break;
          case 'twitter':
            url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.text)}&url=${encodeURIComponent(this.link)}`;
            window.open(url, this.type, 'width=550,height=450,scrollbars=yes,toolbar=no,location=yes');
            break;
          case 'whatsapp':
            url = `https://wa.me/?text=${encodeURIComponent(`${this.text} ${this.link}`)}`;
            window.open(url);
            break;
          case 'linkedin':
            url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`${this.link}`)}`;
            window.open(url);
          break;
          case 'mail':
            url = `mailto:?subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(`${this.text} ${this.link}`)}`;
            window.open(url);
            break;
          default:
            console.error(`Share channel '${this.type}' not available`);
        }
      },
      isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      },
    }
}
</script>

<style lang="scss" scoped>
  .social-button {
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    padding: 0;
    border-radius: 50%;
    background: #E0E0E0;
    @include tablet-down{
      &:last-of-type{
        margin-right: 0;
      }
    }
    &.heading-share--btn{
      width: auto;
      height: auto;
      padding: 12px 24px;
      border-radius: 16px;
    }
    img{
      max-width: 20px;
      margin: 0;
    }
    .btn-txt{
      margin-left: 10px;
    }
    &.green{
      background: #1DDDA9;
    }
  }   
</style>