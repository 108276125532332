import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import Detail from "@/views/Detail";
import Verhaal from "@/views/Verhaal";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/verhaal/:slug",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/vertel-jouw-verhaal",
    name: "Verhaal",
    component: Verhaal,
    meta: {
      from: "vertel-jouw-verhaal",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
