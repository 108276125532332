<template>
  <header class="site-header">
    <div class="header-inner">
      <router-link to="/" class="site-logo">
        <img src="@/assets/images/logos/logo_epilepsi_nl.svg" alt="Epilepsi NL logo" />
      </router-link>
      <div class="site-nav">
        <a href="https://epilepsie.digicollect.nl/epilepsienl-collectebus" class="collect" target="_blank" >
          <img src="@/assets/images/icons/icon_collect.svg" alt="icon" />
          Collecteer
        </a>
         <a href="https://www.epilepsie.nl/doneren" class="donate" target="_blank" >
           <img src="@/assets/images/icons/favorite.svg" alt="icon" />
          Doneer
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  methods:{
    logoClick(){
        this.$router.push(`/`);
    }
  }
}
</script>

<style lang="scss" scoped>
.site-header{
  position: relative;
  width: 100%;
  background: white;
  z-index: 1;
  .header-inner{
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 16px 48px;
    @include tablet-down{
      padding: 12px 16px;
    }
    .site-logo{
      max-width: 220px;
      line-height: 0;
      @include tablet-down{
        max-width: 140px;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    .site-nav{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      a{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // min-width: 140px;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        color: $black;
        text-decoration: none;
        background: #E0E0E0;
        border-radius: 16px;
        @include tablet-down{
          padding: 6px 12px;
          font-size: 12px;
        }
        img{
          margin-right: 10px;
          @include tablet-down{
            display: none;
          }
        }
        &.collect{
          margin-right: 24px;
          @include tablet-down{
            margin-right: 8px;
          }
        }
        &.donate{
          color: white;
          background: #6406DC;
        }
      }
    }
  }
}
</style>
