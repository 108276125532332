<template>
    <div class="file-upload-holder">
      <Button class="button" @onClick="$refs[name].click()">{{label}}</Button>
      <input type="file" :ref="name" :id="name" :name="name" @change="onChange($event.target.files)" accept="image/*" class="input-file">
      <span class="error-msg" v-if="errorMsg">
        <img src="@/assets/images/icons/error.svg" alt="icon error"/>
        {{errorMsg}}
      </span>
    </div>
</template>
<script>
import Button from '@/components/Button';

export default {
    name: 'FileUpload',
    components: { Button },
    props: {
        name: String || null,
        label: String || null,
        fileName: String || null,
        errorMsg: String,
    },
    methods: {
        onChange(files) {
            this.$emit('input', files[0])
        }
    }
}
</script>
<style lang="scss" scoped>
  .file-upload-holder {
    margin-bottom: 24px;
    .button {
      width: 100%;
      cursor: pointer;
      background: #E0E0E0;
      border-radius: 16px;
    }
    input[type=file] {
      display: none;
    }
    
  .error-msg{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #FF4C00;
    img{
      max-width: 15px;
      margin-right:6.5px;
    }
  }
  }
</style>