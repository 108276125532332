<template>
    <div id="popup-overlay" class="popup-overlay">
      <div id="popup" class="popup">
        <span id="close-button" @click="closePopup">
            <img src="@/assets/images/icons/close.svg" alt="icon" />
        </span>
        <div class="popup-content">
          <h2>Deel jouw verhaal met anderen</h2>
          <p>Dank voor het delen van jouw verhaal! Jouw verhaal is uniek en waardevol, het raakt, inspireert en transformeert. Deel het platform met je netwerk, voor meer begrip voor epilepsie!</p>
        </div>
        <div class="social-share--btns">
          <SocialButton :text="shareTitle" :link="windowLocation" type="twitter"/>
          <SocialButton :text="shareTitle" :link="windowLocation" type="facebook"/>
          <SocialButton :text="shareTitle" :link="windowLocation" type="whatsapp"/>
          <SocialButton :text="shareTitle" :link="windowLocation" type="linkedin"/>
        </div>
      </div>
    </div>
</template>

<script>
import SocialButton from '@/components/SocialButton';
export default {
  name: 'ShareModal',
    props: {
    shareTitle: String,
  },
  components: {
    SocialButton,
  },
  data() {
    return {
    }
  },
  computed: {
    windowLocation() {
      return window.location.href;
    },
  },
  methods: {
    closePopup() {
      const popup = document.getElementById("popup-overlay");
      popup.style.display = "none";
      document.body.style.overflow = "auto";
    },
  },
};
</script>


<style lang="scss" scoped>
  .popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: rgba(18, 18, 18, 0.5);
    z-index: 100;
    overflow: hidden;
    padding: 10px;
    .popup {
      position: relative;
      max-width: 708px;
      width: 100%;
      margin: 0 auto;
      padding: 64px 16px;
      background: #FFFFFF;
      border: 1px solid rgba(29, 29, 27, 0.1);
      border-radius: 16px;
      @include desktop-up{
        max-width: 996px;
        padding: 64px;
      }

      .popup-content{
        h2{
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 12px;
          @include tablet-down{
            font-size: 24px;
            line-height: 32px;
          }
        }
        p{
          font-size: 16px;
          line-height: 24px;
          color: #616161;
        }
      }
      #close-button {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }
      .social-share--btns{
        display: flex;
        margin-top: 24px;
        .social-button{
          background: $purple;
         ::v-deep img{
            filter: invert(100%);
          }
        }
      }
    }
  }
</style>
