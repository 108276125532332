<template>
  <div class="form-group">
    <div class="btn-wrap">
        <input type="checkbox" :name="name" :id="`${name}`" @change="onChange">
        <label :for="`${name}`" v-html="`${label}${isRequired ? '*' : ''}`"/>
    </div>
      <span class="error-msg" v-if="errorMsg">
      <img src="@/assets/images/icons/error.svg" alt="icon error"/>
      {{errorMsg}}
    </span>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        name: String || null,
        isRequired: Boolean || null,
        label: String || null,
        errorMsg: String,
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.checked, this.name);
        }
    }
}
</script>
<style scoped lang="scss">
  .form-group {
    width: 100%;
    margin-bottom: 24px;
    .btn-wrap{
      width: 100%;
      display: flex;
      align-items: flex-start;
      @include mobile{
        flex-wrap: wrap;
      }
      label{
      display: block;
      max-width: 100%;
      margin-left: 10px;
      font-family: $fontStack;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #616161;
      @include mobile{
        max-width: 280px;
      }
    }
      input[type=checkbox] {
        margin-left: 0;
        margin-right: 0;
        border: 1px solid #838383;
        font-family: $fontStack;
        &:checked {
            background-position: center;
            background-repeat: no-repeat;
        }
        &:focus {
          outline: none;
        }
      }
    }

  .error-msg{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #FF4C00;
    img{
      max-width: 15px;
      margin-right:6.5px;
    }
  }
  }
</style>