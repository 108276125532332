import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    form: {},
  },
  mutations: {
    setFormData: (state, payload) =>
      (state.formData = { ...state.form, ...payload }),
  },
  actions: {
    setFormData: (state, payload) => state.commit("setForm", payload),
  },
  getters: {
    getFormData: (state) => state.formData,
  },
});
