<template>
  <article class="card">
    <div class="card-inner">
      <router-link class="card-link" :to="`/verhaal/${href}`" v-if="cardTitle">
        <figure class="card-image">
          <img :src="image" alt="post thumbnail" />
        </figure>
        <div class="card-body">
          <div>
            <h2 v-if="cardTitle">{{cardTitle}}</h2>
            <p v-if="author">door {{author}}</p>
          </div>
          <span class="read-more">Lees meer <img src="@/assets/images/icons/chevron_right.svg" alt="icon" /></span>
        </div>
      </router-link>
      <router-link class="card-link" to="/vertel-jouw-verhaal" v-if="ctaTitle">
        <figure class="card-image">
          <img src="@/assets/images/backgrounds/share_story.svg" alt="icon" />
        </figure>
        <div class="card-body">
          <h2 v-if="ctaTitle">{{ctaTitle}}</h2>
          <p>{{ctaText}}</p>
          <span class="read-more">Lees meer <img src="@/assets/images/icons/chevron_right.svg" alt="icon" /></span>
        </div>
      </router-link>
      <div class="cta-card" v-if="shareTitle || factsTitle" :class="{ share_card: shareTitle }">
        <h2 v-if="shareTitle">{{ shareTitle }}</h2>
        <span v-if="factsMetaTitle" class="meta-title">{{ factsMetaTitle}}</span>
        <h2 v-if="factsTitle">{{ factsTitle}}</h2>
        <div class="button-holder" v-if="shareTitle">
          <SocialButton :text="shareText" :link="windowLocation" type="facebook"/>
          <SocialButton :text="shareText" :link="windowLocation" type="twitter"/>
          <SocialButton :text="shareText" :link="windowLocation" type="linkedin"/>
        </div>
      </div>
      </div>
  </article>
</template>

<script>
import SocialButton from '@/components/SocialButton';
export default {
  name: 'Card',
  props: {
    image: String,
    cardTitle: String,
    ctaTitle: String,
    shareTitle: String,
    factsTitle: String,
    factsMetaTitle: String,
    ctaText: String,
    href: String,
    author: String,
    shareText: String
  },
  components: {
       SocialButton
  },
  data() {
    return {};
  },
  computed: {
    windowLocation() {
      return window.location.href;
    },
  },
    mounted() {
    const ctaCard = this.$el.querySelector('.cta-card');
    if (ctaCard) {
      const colors = ["#1DDDA9", "#FCBC14", "#00BAFD"];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      ctaCard.style.backgroundColor = randomColor;
    }
  },
};
</script>

<style lang="scss" scoped>
  .card{
    width: 100%;
    height: 100%;
    @include tablet-up{
      flex: 0 0 50%;
      max-width: 50%;
      height: auto;
      padding: 0 12px;
    }
    @include desktop-up{
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .card-inner{
      width: 100%;
      height: 100%;
      a.card-link{
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: inherit;
          display: flex;
          flex-direction: column;
          height: 100%;
          border-radius: 16px;
          box-shadow: 0px 8px 24px rgba(18, 18, 18, 0.1);
          background-color: #fff;
          overflow: hidden;
        .card-image{
          width: 100%;
          height: 100%;
          max-height: 215px;
          margin: 0;
          aspect-ratio: 16/9;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .card-body{
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
          justify-content: space-between;
          padding: 24px;
          border: 1px solid rgba(18, 18, 18, 0.1);
          border-top: none;
          border-radius: inherit;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          h2{
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Limit the text to 2 lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; /* Add ellipsis (...) at the end */
            white-space: normal; /* Use normal white-space handling */
            margin-bottom: 12px;
          }
          p{
            font-size: 12px;
            line-height: 20px;
            color: #616161;
          }
          .read-more{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 700;
            font-size: 16px;
            line-height:0;
            color: $purple;
            margin-top: 18px;
            img{
              margin-left: 12px;
            }
          }
        }
      }
      .cta-card{
        position: relative;
        width: 100%;
        height: inherit;
        padding: 24px;
        background: #FCBC14;
        border-radius: 16px;
         box-shadow: 0px 8px 24px rgba(18, 18, 18, 0.1);
         @include tablet-up{
            min-height: 354px;
         }
        .meta-title{
          display: inline-block;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
        h2{
          font-size: 32px;
          line-height: 40px;
        }
        .button-holder{
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          @include tablet-up{
            position: absolute;
            bottom: 24px;
          }
        }
        &.share_card{
          h2{
            font-size: 28px;
            line-height: normal;
          }
        }
      }
    }
  }
</style>
