<template>
  <section class="page-heading">
    <div class="holder">
      <div class="breadcrumbs">
          <router-link to="/"> <img src="@/assets/images/icons/chevron_left.svg" alt="icon error"/>Bekijk alle verhalen</router-link>
      </div>
      <div class="heading-content--wrap">
        <h1>{{ title }}</h1>
        <span class="author" v-if="author">Door {{author}}</span>
          <Button theme="green" route="/vertel-jouw-verhaal">
            <img src="@/assets/images/icons/mail.svg" alt="icon" />
            Deel jouw verhaal
          </Button>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'PageHeading',
  props: {
    title: String,
    author: String,
  },
  components: {
    Button,
  },
  computed: {
    windowLocation() {
      return window.location.origin;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-heading{
  position: relative;
  width: 100%;
  background: $purple;
  .holder{
    max-width: 792px;
    width: 100%;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 24px;
    color: white;
    @include tablet-down{
      padding: 48px 16px 24px 16px;
    }
    .breadcrumbs{
      margin-bottom: 24px;
      a{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: inherit;
        img{
          margin-right: 16px;
        }
      }
    }
    .heading-content--wrap{
      h1{
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
        @include tablet-down{
          font-size: 32px;
          line-height: 40px;
        }
      }
      .author{
        display: block;
        margin: 12px 0 24px 0;
      }
    }
  }
}
</style>