<template>
  <div class=" page home">
    <SectionHeader title="Leven met epilepsie" introText="Iedereen ervaart het leven met epilepsie anders. Voor meer begrip willen we zoveel mogelijk verschillende ervaringen verzamelen én delen! Help jij mee?"/>

    <div class="section-grid">
      <div class="bg-top"></div>
      <div class="holder container">
        
        <div class="grid-heading">
          <Button theme="green" route="/vertel-jouw-verhaal">
            Deel jouw verhaal
          </Button>
          <div class="search-wrapper">
            <div class="searchbar-inner">
              <input class="col search-input" type="text" v-model="search" placeholder="Naar welk verhaal ben je op zoek?"/>
              <div class="search-icon"> 
                <img src="@/assets/images/icons/search.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="grid" v-if="allPosts.length > 0">
        <Card  v-for="(card, i) in allPosts.slice(0, displayedItems)"  :key="i"
          :href="card.slug"
          :image="card.image"
          :cardTitle="card.title"
          :ctaTitle="card.ctaTitle"
          :shareTitle="card.shareCardTitle"
          :factsMetaTitle="card.meta"
          :factsTitle="card.tekst"
          :ctaText="card.ctaText"
          :author="card.name"
          shareText="Leven met epilepsie"
        />
        <div class="btn-wrapper" >
          <Button class="load-more--btn" v-if="shouldShowLoadMoreButton()" @click.native="loadMoreItems">
            Toon meer
            <img src="@/assets/images/icons/expand_more.svg" alt="icon" />
          </Button>
        </div>
      </div>
      <div class="no-results" v-else>
        <p>Er zijn helaas geen zoekresultaten gevonden.</p>
      </div>
      </div>
    </div>
  </div>
</template>


<script>
import SectionHeader from "@/components/SectionHeader";
import Card from "@/components/Card";
import Button from "@/components/Button";
// import generateSlug from "@/helpers/slug";

// JSON data
import ctaToForm from "@/assets/data/ctaToForm.json";
import siteSharing from "@/assets/data/siteSharing.json";
import interestingFacts from "@/assets/data/interestingFacts.json";
export default {
  name: "Home",
  components: {
    SectionHeader,
    Card,
    Button,
  },
  data() {
    return {
      posts: [],
      ctaToForm:ctaToForm,
      facts:interestingFacts,
      siteSharing:siteSharing,
      search: '',
      displayedItems: 9,
    };
  },
  metaInfo() {
    return {
      title: 'EpilepsieNL - Leven met epilepsie',
      meta: [
        { name: 'description', content: 'Leven met epilepsie kent vele vormen. Voor meer begrip willen we een representatief beeld geven van het leven met epilepsie. Wat betekent het voor jou?' },
        { property: 'og:title', content: 'EpilepsieNL - Leven met epilepsie' },
        { property: 'og:description', content:"Leven met epilepsie kent vele vormen. Voor meer begrip willen we een representatief beeld geven van het leven met epilepsie. Wat betekent het voor jou?"},
        { property: 'og:image', content: `${this.windowLocation}/share-image.jpg` },
        { property: 'twitter:title', content: 'EpilepsieNL - Leven met epilepsie' },
        { property: 'twitter:description', content: "Leven met epilepsie kent vele vormen. Voor meer begrip willen we een representatief beeld geven van het leven met epilepsie. Wat betekent het voor jou?" },
        { property: 'twitter:image', content: `${this.windowLocation}/share-image.jpg` },
      ],
    };
  },
  computed: {
    windowLocation() {
      return window.location.origin;
    },
    allPosts() {
      // Filter posts based on search criteria
      const filteredPosts = this.posts.filter(post => {
        return post.title.toLowerCase().includes(this.search.toLowerCase()) || post.name.toLowerCase().includes(this.search.toLowerCase());
      });

      // Define the block size for content grouping
      const blockSize = 6;

      // Initialize an empty array to store the result
      const result = [];

      // Initialize the content index
      let contentIndex = 0;

      // Iterate through the filtered posts
      for (let i = 0; i < filteredPosts.length; i++) {
        // Check if the current index is a multiple of the block size
        if (i > 0 && i % blockSize === 0) {
          // Check if this.facts is an array
          if (Array.isArray(this.facts)) {
            // Add a fact to the result based on the content index
            result.push(this.facts[contentIndex]);
            // Update the content index to cycle through this.facts
            contentIndex = (contentIndex + 1) % this.facts.length;
          }
        }

        // Add the current filtered post to the result
        result.push(filteredPosts[i]);

        // Check if the current index is 1
        if (i === 1) {
          // Check if this.ctaToForm is an array
          if (Array.isArray(this.ctaToForm)) {
            // Add each item in this.ctaToForm to the result
            this.ctaToForm.forEach((item) => result.push(item));
          }
          
          // Check if this.siteSharing is an array
          if (Array.isArray(this.siteSharing)) {
            // Add each item in this.siteSharing to the result
            this.siteSharing.forEach((item) => result.push(item));
          }
        }
      }

      // Return the final result
      return result;
    }

  },
  mounted() {
    this.fetchPosts();
      window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    fetchPosts() {
      this.axios.get(`${process.env.VUE_APP_API_URL}/api/submissions/get`)
        .then((response) => {
          // Shuffle the response data array randomly
          const shuffledPosts = this.shuffleArray(response.data.submissions);

          // Concatenate the new shuffled posts with the existing ones using the spread operator.
          this.posts = [...this.posts, ...shuffledPosts];

          // Set the 'loading' flag to false to indicate that data fetching is complete.

          // Iterate over shuffledPosts and create the desired data structure
        })
        .catch((error) => {
          console.error(error);
          // Handle any errors that occur during the API request
          // ...
        });
    },
    shuffleArray(array) {
      // Create a copy of the original array
      const shuffledArray = [...array];

      // Perform Fisher-Yates shuffle algorithm
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }

      return shuffledArray;
    },
    handleScroll() {
      // Get the total height of the document
      const scrollHeight = document.documentElement.scrollHeight;
      
      // Get the current vertical scroll position
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      // Get the height of the visible portion of the document
      const clientHeight = document.documentElement.clientHeight;

      // Check if the user has scrolled near the bottom of the page
      if (scrollTop + clientHeight >= scrollHeight - 20 && this.displayedItems < this.allPosts.length) {
        // Increase the number of displayed items by 9
        this.displayedItems += 9;
      }
    },
    // loadMore button as fallback for scroll
    shouldShowLoadMoreButton() {
      return this.displayedItems < this.allPosts.length;
    },
    loadMoreItems() {
      // Increase the number of displayed items by 9
      this.displayedItems += 9;
    }
  },

};
</script>

<style lang="scss" scoped>
.home{
  background: $purple;
  ::v-deep .section-heading{
    .heading-wrap{
      max-width: 996px;
    }
  }
}
  .section-grid {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin-bottom: 64px;
    background-color: white;
    @include tablet-down{
      margin-bottom: 48px;
    }
    .bg-top{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      min-height:400px;
      background: $purple;
      background: $purple;
    }
    .holder{
      position: relative;
      .grid-heading{
        padding: 24px 16px 32px 16px;
        @include tablet-up {
          padding: 32px 0 48px 0;
        }
        .button{
          margin: 0 auto;
        }
        .search-wrapper {
          max-width: 600px;
          position: relative;
          margin: 24px auto 0;
          padding: 5px 16px;
          border-radius: 12px;
          background: white;
          overflow: hidden;
          @include tablet-up {
            margin-top: 32px;
          }

          .searchbar-inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .search-input {
              flex: 1;
              padding: 12px 0;
              border: none;
              outline: none;
              appearance: none;
              -webkit-appearance: none;
              background: none;

              &::placeholder {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #616161;
              }
              &:focus {
                outline: none;
              }
            }
            .search-icon {
              line-height: 0;
            }
          }
        }
      }
    }
    .grid {
      padding: 0 16px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 24px;
      @include tablet-up {
        padding: 0 16px;
      }
      .btn-wrapper{
        width: 100%;
        .load-more--btn{
          width: 100%;
          max-width: 250px;
          align-items: center;
          margin: 0 auto;
          margin-top: 48px;
          @include tablet-down{
            margin-top: 32px;
          }
          img{
            max-width: 15px;
            margin: 0;
            margin-left: 10px;
          }
        }
      }
    }
    .no-results{
      max-width: 768px;
      position: relative;
      text-align: center;
      margin: 24px auto 0;
      padding: 5px 16px;
      overflow: hidden;
      p{
        color: white;
        font-size: 24px;
      }
    }
  }
</style>

