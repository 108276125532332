<template>
  <div class="form-group textarea">  
    <label v-if="label">{{ label }}</label>  
    <textarea 
      :name="name" 
      :placeholder="placeholder" 
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"/>
    <span class="error-msg" v-if="errorMsg">
      <img src="@/assets/images/icons/error.svg" alt="icon error"/>
      {{errorMsg}}
    </span>
    </div>
</template>

<script>
export default {
    name: 'Textarea',
    inheritAttrs: false,
    props: {
        label:String,
        name: String || null,
        placeholder: String || null,
        model: String || null,
        errorMsg: String,
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        }
    }
}
</script>
<style lang="scss" scoped>
.form-group{
  width: 100%;
  margin-bottom: 24px;
  label{
    display: block;
    font-family: $fontStack;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  }
 textarea {
    width: 100%;
    min-height: 96px;
    padding: 12px 16px;
    font-family: $fontStack;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    background: #FFFFFF;
    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #616161;
    }
    &:focus{
       outline: none;
    }
  }
  .error-msg{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #FF4C00;
    img{
      max-width: 15px;
      margin-right:6.5px;
    }
  }
}
</style>