<template>
    <footer class="site-footer">
      <div class="footer-top">
        <router-link to="/" class="site-logo">
          <img src="@/assets/images/logos/logo_footer.svg" alt="Epilepsi NL logo" />
        </router-link>
        <div class="footer-btn--wrap">
          <div class="footer-social-links">
            <a href="https://twitter.com/epilepsie_NL" target="_blank" rel="nofollow" class="footer-social-link">
              <img src="@/assets/images/icons/twitter.svg" alt="icon" />
            </a>
            <a href="https://www.facebook.com/EpilepsieNL" target="_blank" rel="nofollow" class="footer-social-link">
              <img src="@/assets/images/icons/facebook.svg" alt="icon" />
            </a>
            <a href="https://www.instagram.com/epilepsienl" target="_blank" rel="nofollow" class="footer-social-link">
              <img src="@/assets/images/icons/instagram.svg" alt="icon" />
            </a>
            <a href="https://nl.linkedin.com/company/epilepsienl" target="_blank" rel="nofollow" class="footer-social-link">
              <img src="@/assets/images/icons/linkedin.svg" alt="icon" />
            </a>
          </div>
          <Button theme="purple" href="https://www.epilepsie.nl/doneren">
            <img src="@/assets/images/icons/favorite.svg" alt="icon" />
            Doneer
          </Button>
        </div>
      </div>
      <div class="footer-bottom">
        <nav class="footer-nav">
          <ul>
            <li><a href="https://www.epilepsie.nl/cookiesverklaring" target="_blank">Cookiesverklaring</a></li>
            <li><a href="https://www.epilepsie.nl/privacyverklaring" target="_blank">Privacyverklaring</a></li>
            <li><a href="https://www.epilepsie.nl/disclaimer" target="_blank">Disclaimer</a></li>
          </ul>
        </nav>
        <div class="footer-sponsor-logos">
          <a href="https://www.epilepsie.nl/vriendenloterij" target="_blank" rel="nofollow" class="footer-sponsor-link">
            <img src="@/assets/images/logos/logo_vrienden_loterij.svg" alt="Vriendenloterij" />
          </a>
          <a href="https://www.epilepsie.nl/vriendenloterij" target="_blank" rel="nofollow" class="footer-sponsor-link">
            <img src="@/assets/images/logos/npl.svg" alt="NPL" />
          </a>
          <a href="https://www.cbf.nl/" target="_blank" rel="nofollow" class="footer-sponsor-link">
            <img src="@/assets/images/logos/cbf.svg" alt="cbf" />
          </a>
          <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/bijzondere_regelingen/goede_doelen/algemeen_nut_beogende_instellingen/belastingregels_algemeen_nut_beogende_instellingen" target="_blank" rel="nofollow" class="footer-sponsor-link">
            <img src="@/assets/images/logos/anbi.svg" alt="anbi" />
          </a>
        </div>
      </div>
    </footer>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: 'Footer',
  components: {
    Button,
  },
}
</script>

<style lang="scss" scoped>
.site-footer{
    width: 100%;
    text-align: center;
    padding: 64px 16px;
    background: $gray;
    .footer-top{
      max-width: 1200px;
      margin: 0 auto;
      border-bottom: 2px solid #E0E0E0;
      .site-logo{
        max-width: 260px;
        display: block;
        margin: 0 auto;
        img{
          width: 100%;
          height: auto;
        }
      }
      .footer-btn--wrap{
        margin-top: 24px;
        margin-bottom: 32px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .footer-social-links{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-right: 12px;
          @include tablet-down{
            width: 100%;
            justify-content: center;
            margin-bottom: 24px;
          }
          a{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px;
            width: 48px;
            height: 48px;
            margin-right: 12px ;
            border-radius: 24px;
            background: #E0E0E0;
            @include tablet-down{
              &:last-of-type{
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .footer-bottom{
       max-width: 1200px;
       margin: 0 auto;
      .footer-nav{
        margin: 0 auto;
        margin-top: 32px;
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
          padding: 0;
          li{
            margin-right: 24px;
            list-style: none;
            &:last-of-type{
              margin-right: 0;
            }
            a{
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #616161;
              text-decoration: none;
            }
          }
        }
      }
      .footer-sponsor-logos{
        margin-top: 24px;
        @include tablet-down{
          max-width: 250px;
          margin: 0 auto;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 24px;
        }
        a{
          display: inline-block;
          max-width: 100px;
          margin-right: 24px;
          &:last-of-type{
            margin-right: 0;
          }
          @include tablet-down{
            margin-right: 0;
          }
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
}
</style>
