<template>
  <section class="page-content">
    <div class="holder">
      <div class="wrapper">
        <div class="date" v-if="date">{{ date }}</div>
        <div class="content-wrap">
          <p>{{ text }}</p>
        </div>
        <div class="donation-btn">
          <div v-if="donate === 'default' || donate === 'Geef online'">
            <Button theme="purple" href="https://www.epilepsie.nl/doneren">
              <img src="@/assets/images/icons/favorite.svg" alt="icon" />
              Doneer online
            </Button>
          </div>
          <div
            v-else-if="
              donate === 'custom' || donate === 'Geef aan mijn collectebus'
            "
          >
            <Button theme="purple" :href="customLink">
              <img src="@/assets/images/icons/favorite.svg" alt="icon" />
              Steun mijn collecte
            </Button>
          </div>
        </div>
        <div class="social-share">
          <span class="title">Deel dit verhaal:</span>
          <div class="social-share--btns">
            <SocialButton
              :text="this.title"
              :link="windowLocation"
              type="linkedin"
            />
            <SocialButton
              :text="this.title"
              :link="windowLocation"
              type="whatsapp"
            />
            <SocialButton
              :text="this.title"
              :link="windowLocation"
              type="facebook"
            />
            <SocialButton
              :text="this.title"
              :link="windowLocation"
              type="twitter"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SocialButton from "@/components/SocialButton";
import Button from "@/components/Button";
export default {
  name: "PageBody",
  props: {
    date: String,
    title: String,
    shareImage: String,
    text: String,
    donate: String,
    customLink: String,
    reportBtn: String,
  },
  components: {
    SocialButton,
    Button,
  },
  computed: {
    windowLocation() {
      return window.location.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  width: 100%;
  .holder {
    max-width: 792px;
    width: 100%;
    margin: 0 auto;
    @include tablet-down {
      padding: 0 16px;
    }
    .wrapper {
      .date {
        font-size: 14px;
        line-height: 22px;
        color: #616161;
        margin-bottom: 48px;
        @include tablet-down {
          margin-bottom: 32px;
        }
      }
      .content-wrap {
        p {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 48px;
          white-space: pre-wrap;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .date {
          display: block;
          margin: 12px 0 24px 0;
        }
      }
      .donation-btn {
        margin-top: 48px;
        @include tablet-down {
          margin-top: 32px;
        }
      }
      .social-share {
        margin-top: 48px;
        .title {
          display: block;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        .social-share--btns {
          display: flex;
          flex-wrap: wrap;
        }
      }
      .report {
        margin-top: 48px;
      }
    }
  }
}
</style>