<template>
  <article class="detail">
    <PageHeading 
      :title="content.title" 
      :author="content.name"
      :shareImageUrl="content.image"
    />
    <PageImage 
      :image="content.image"
    />
    <PageBody 
        :date="formattedDate" 
        :title="content.title"
        :shareImage="content.image"
        :text="content.message"
        :donate="content.donation_link_type"
        :customLink="content.custom_donation_url"
      />
      <div class="report-wrapper">
        <div class="holder">
          <Button class="report" @click="report" id="report" :class="{ 'clicked': clicked }">
            <img src="@/assets/images/icons/report.svg" alt="icon" />
            Meld als ongepast
          </Button>
        </div>
      </div>
      <ShareModal :shareTitle="this.shareTitle"  v-if="showPopup"/>
  </article>
</template>

<script>
import PageHeading from "@/components/detail/PageHeading";
import PageImage from "@/components/detail/PageImage";
import PageBody from "@/components/detail/PageBody";
import ShareModal from "@/components/ShareModal";
// import generateSlug from '@/helpers/slug';
export default {
  name: 'Detail',
  components: {
    PageHeading,
    PageImage,
    PageBody,
    ShareModal,
  },
  metaInfo() {
    return {
      title: this.shareTitle,
      meta: [
        {
          property: 'og:title',
          content: this.shareTitle,
        },
        {
          property: 'og:description',
          content: 'Leven met epilepsie kent vele vormen. Voor meer begrip willen we een representatief beeld geven van het leven met epilepsie. Wat betekent het voor jou?',
        },
        {
          property: 'og:image',
          content: `${this.baseUrl}/share-image.png`,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: this.shareTitle,
        },
        {
          name: 'twitter:description',
          content: 'Leven met epilepsie kent vele vormen. Voor meer begrip willen we een representatief beeld geven van het leven met epilepsie. Wat betekent het voor jou?',
        },
        {
          name: 'twitter:image',
          content: `${this.baseUrl}/share-image.png`,
        },
      ],
    };
  },
  data() {
    return {
      content: [],
      clicked: false,
      showPopup: false
    }
  },
async mounted() {
  

  const slug = this.$route.params.slug;
  try {
    const response = await this.axios.get(`${process.env.VUE_APP_API_URL}/api/submissions/detail?slug=${slug}`);
    const submission = response.data.submission;
    this.content = submission;
  } catch (error) {
    console.log('Error:', error);
  }
},
computed: {
    shareTitle(){
      return this.content.title
    },
    baseUrl(){
      return window.location.origin;
    },
    shareImage(){
      return this.content.image
    },
   formattedDate() {
      const createdAt = this.content.created_at;
      const date = new Date(createdAt);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('nl-NL', options);
    },
  },
  methods: {
    async report() {
      const id = this.content.id;
      if (!this.clicked) {
        this.clicked = true;
        await this.axios.post(`${process.env.VUE_APP_API_URL}/api/submissions/report?id=${id}`);
      }
    },
    closePopup() {
      const popup = document.getElementById("popup-overlay");
      popup.style.display = "none";
      document.body.style.overflow = "auto";
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path === '/vertel-jouw-verhaal') {
        vm.showPopup = true;
        document.body.style.overflow = "hidden";
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.detail{
  margin-bottom: 64px;
  @include tablet-down{
    margin-bottom: 48px;
  }
  .report-wrapper{
    margin-top: 48px;
    @include tablet-down{
      margin-bottom: 32px;
    }
    .holder{
      max-width: 792px;
      width: 100%;
      margin: 0 auto;
      @include tablet-down{
        padding: 0 16px
      }
      .report{
        &.clicked{
          color: white;
          background: #6406DC;
          img{
            -webkit-filter: invert(100%); /* Safari/Chrome */
            filter: invert(100%);
          }
        }
        &:hover{
          color: white;
          background: #6406DC;
          img{
            -webkit-filter: invert(100%); /* Safari/Chrome */
            filter: invert(100%);
          }
        }
      }
    }
  }
}




</style>
