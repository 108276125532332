<template>
  <section class="page-image">
    <div class="holder">
      <figure class="image">
        <img :src="image" alt="" />
      </figure>
    </div>
    <div class="bg-top"></div>
  </section>
</template>

<script>
  export default {
    name: 'PageImage',
    props: {
      image: String,
    },
    components: {
    },
  }
</script>

<style lang="scss" scoped>
.page-image{
  position: relative;
  width: 100%;
    .bg-top{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height:250px;
    background: $purple;
    @include tablet-down{
      min-height: 100px;
    }
  }
  .holder{
    position: relative;
    max-width: 792px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: white;
    z-index: 1;
    @include tablet-down{
      padding: 0 16px ;
    }
    .image{
      margin: 0;
      height: 100%;
      line-height: 0;
      margin-bottom: 48px;
      @include tablet-down{
        margin-bottom: 32px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16/9
        ;
      }
    }
    @include tablet-down{

    }
  }
}
</style>