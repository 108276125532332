<template>
  <div class="page">
    <SectionHeader
      title="Wat betekent epilepsie voor jou?"
      introText="Wat betekent epilepsie voor jou persoonlijk? Voor meer begrip schetsen we samen een compleet beeld van leven met epilepsie!"
    />
    <section class="section-block--form">
      <div class="bg-top"></div>
      <div class="form-holder">
        <div class="form-inner">
          <form id="my-form" class="form" @submit="submitForm">
            <FileUpload
              name="image"
              label="Voeg jouw foto toe"
              :fileName="fileName"
              @input="onFileHandle"
              :errorMsg="errors.uploadFile"
            />
            <TextInput
              label="Voeg jouw titel toe"
              name="title"
              placeholder="Voeg een titel toe"
              v-model="form.title"
              :errorMsg="errors.title"
            />
            <Textarea
              label="Vertel je verhaal"
              name="message"
              placeholder="Plaats hier je verhaal"
              v-model="form.message"
              :errorMsg="errors.message"
            />
            <div class="form-group radio-btns--group">
              <span class="block-title">Wil je een link toevoegen?</span>
              <div
                class="btn-wrap"
                v-for="(option, index) in form.options"
                :key="index"
              >
                <input type="radio" :value="option" v-model="selectedOption" />
                <label :for="'option-' + index">{{ option }}</label>
              </div>
              <div class="error-msg" v-if="errors.selectedOption">
                <img src="@/assets/images/icons/error.svg" alt="icon error" />
                {{ errors.selectedOption }}
              </div>
            </div>
            <TextInput
              label="Link collectebus"
              name="title"
              placeholder="Plaats hier je link bv. (https://epilepsie.digicollect.nl/)"
              v-if="selectedOption === 'Geef aan mijn collectebus'"
              v-model="form.custom_donation_url"
              :errorMsg="errors.donation_url"
            />

            <TextInput
              label="Jouw naam (wordt getoond bij je verhaal)"
              name="title"
              placeholder="Jouw naam"
              :value="form.name"
              v-model="form.name"
              @input="form.name = $event"
              :errorMsg="errors.name"
            />
            <EmailInput
              label="E-mail adres (wordt niet getoond)"
              name="title"
              placeholder="E-mail adres"
              :errorMsg="errors.email"
              v-model="form.email"
              @input="form.email = $event"
            />
            <Checkbox
              name="updateOptin"
              v-model="form.acceptTerms"
              @input="form.acceptTerms = $event"
              label="Ja, ik geef EpilepsieNL toestemming om mijn persoonlijke gegevens op te slaan en mijn verhaal te delen"
              :errorMsg="errors.acceptTerms"
            />
            <Checkbox
              name="updateOptin"
              v-model="form.newsletterRegistration"
              @input="form.newsletterRegistration = $event"
              label="Ik blijf graag via e-mail op de hoogte van nieuws, updates over onderzoeken en manieren waarop ik kan bijdragen aan EpilepsieNL."
            />
            <Button class="submit-btn" :disabled="isSubmitDisabled" submit
              >Jouw verhaal versturen</Button
            >
            <FormPreview
              :title="form.title"
              :text="form.message"
              :imageUrl="imagePreview"
              :author="form.name"
            />
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import EmailInput from "@/components/form-inputs/EmailInput";
import {
  TextInput,
  Textarea,
  Checkbox,
  FileUpload,
} from "@/components/form-inputs";
import FormPreview from "@/components/form-inputs/FormPreview";
// import imageCompression from 'browser-image-compression';
import axios from "axios";

export default {
  name: "Verhaal",
  components: {
    SectionHeader,
    TextInput,
    Textarea,
    FileUpload,
    EmailInput,
    Checkbox,
    FormPreview,
  },
  metaInfo() {
    return {
      title: "Wat betekent epilepsie voor jou?",
      meta: [
        {
          name: "description",
          content:
            "Wat betekent epilepsie voor jou persoonlijk? Voor meer begrip schetsen we samen een compleet beeld van leven met epilepsie!",
        },
        { property: "og:title", content: "Wat betekent epilepsie voor jou?" },
        {
          property: "og:image",
          content: `${this.windowLocation}/share-image.jpg`,
        },
        {
          property: "og:description",
          content:
            "Wat betekent epilepsie voor jou persoonlijk? Voor meer begrip schetsen we samen een compleet beeld van leven met epilepsie!",
        },
        {
          property: "twitter:title",
          content: "Wat betekent epilepsie voor jou?",
        },
        {
          property: "twitter:description",
          content:
            "Wat betekent epilepsie voor jou persoonlijk? Voor meer begrip schetsen we samen een compleet beeld van leven met epilepsie!",
        },
        {
          property: "twitter:image",
          content: `${this.windowLocation}/share-image.jpg`,
        },
      ],
    };
  },
  data() {
    return {
      imagePreview: null,
      form: {
        uploadFile: null,
        title: "",
        message: "",
        options: ["Geef online", "Geef aan mijn collectebus", "Nee"],
        custom_donation_url: "",
        name: "",
        email: "",
        id: "",
        newsletterRegistration: 0,
        acceptTerms: "",
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      },
      selectedOption: "Geef online",
      previewURL: null,
      imageUrl: null,
      errors: {}, // Store form validation errors
    };
  },
  computed: {
    windowLocation() {
      return window.location.origin;
    },
    isSubmitDisabled() {
      return !(
        this.form.uploadFile &&
        this.form.title &&
        this.form.message &&
        this.selectedOption &&
        this.form.name &&
        this.form.reg.test(this.form.email) &&
        this.form.acceptTerms
      );
    },
    fileName() {
      return this.form.uploadFile ? this.form.uploadFile.name : null;
    },
  },
  methods: {
    onFileHandle(file) {
      this.form.uploadFile = file;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async submitForm(event) {
      event.preventDefault(); // Prevent default form submission behavior
      this.errors = {}; // Reset form errors

      // Perform form validation
      if (!this.form.uploadFile) {
        this.errors.uploadFile = "Image is required.";
      }
      if (!this.form.title) {
        this.errors.title = "title is required.";
      }
      if (!this.selectedOption) {
        this.errors.selectedOption = "Please select an option.";
      }
      if (!this.form.message) {
        this.errors.message = "Message is required.";
      }
      const urlPattern = /^https:\/\/.+$/;
      if (
        !this.form.custom_donation_url ||
        !urlPattern.test(this.form.custom_donation_url)
      ) {
        this.errors.donation_url =
          "Voeg een valide url in beginnend me https:// ";
      }
      if (!this.form.name) {
        this.errors.name = "Name is required.";
      }
      if (!this.form.reg.test(this.form.email)) {
        this.errors.email = "Email is required.";
      }
      if (!this.form.acceptTerms) {
        this.errors.acceptTerms = "Field is required.";
      }
      if (Object.keys(this.errors).length > 0) {
        return;
      }
      //End of Perform form validation

      try {
        const formData = new FormData();
        formData.append("image", this.form.uploadFile);
        formData.append("title", this.form.title);
        formData.append("message", this.form.message);
        formData.append("name", this.form.name);
        formData.append("email", this.form.email);
        formData.append("custom_donation_url", this.form.custom_donation_url);
        formData.append("newsletter", this.form.newsletterRegistration ? 1 : 0);
        formData.append("donation_link_type", this.selectedOption);

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/submissions/submit`,
          formData
        );

        // Extract the slug from the response or any other relevant data
        const slug = response.data.slug;

        // Generate the route path with the slug
        const routePath = `/verhaal/${slug}`;

        // Navigate to the generated route path
        this.$router.push(routePath);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-block--form {
  position: relative;
  max-width: 100%;
  width: 100%;
  padding-top: 48px;
  @include tablet-down {
    padding: 8px 16px 0 16px;
  }
  .bg-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 250px;
    background: $purple;
  }
  .form-holder {
    position: relative;
    max-width: 792px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 64px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 8px 24px rgba(18, 18, 18, 0.1);
    border-radius: 24px;
    z-index: 1;
    @include tablet-down {
      padding: 24px 16px;
      margin-bottom: 48px;
    }
    .form-inner {
      form {
        .radio-btns--group {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 24px;
          .block-title {
            display: block;
            width: 100%;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 10px;
          }
          .btn-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 14px;
            @include tablet-down {
              width: 100%;
              margin-bottom: 12px;
            }
            @include tablet-up {
              margin-right: 24px;
            }
            input[type="radio"] {
              width: 18px;
              height: 18px;
              margin: 0 10px 0 0;
            }
            label {
              font-size: 14px;
              line-height: 22px;
            }
          }
          .error-msg {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 8px;
            font-size: 12px;
            line-height: 20px;
            color: #ff4c00;
            img {
              max-width: 15px;
              margin-right: 6.5px;
            }
          }
        }
        .submit-btn {
          width: 100%;
          margin-bottom: 24px;
          background: #1ddda9;
        }
      }
    }
  }
}
</style>
