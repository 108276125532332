<template>
    <router-link v-if="route" :to="route" :class="theme" class="button" custom >
      <slot/>
    </router-link>
     <router-link v-else-if="routeName" :to="{name: routeName}" :class="theme" class="button" custom>
      <slot/>
    </router-link>
    <button v-else-if="hasClickHandler" :class="theme" @click.prevent="onClick">
      <slot/>
    </button>
    <a v-else-if="href" class="button" :class="theme" target="_blank" :href="href">
      <slot/>
    </a>
    <button v-else-if="submit" :class="theme" class="button">
      <slot/>
    </button>
    <div v-else class="button" :class="theme"><div v-if="hasIcon" class="icon"></div><slot></slot></div>
</template>

<style lang="scss" >
    button, .button {
        display: flex;
        justify-content: center;
        width: fit-content;
        padding: 12px 24px;
        font-family: $fontStack;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        color: $black;
        text-decoration: none;
        background: #E0E0E0;
        border-radius: 16px;
        border: none;
        transition: all .4s;
        img{
          margin-right: 10px;
        }
        &.purple{
          color: white;
          background: #6406DC;
        }
        &.grey{
          color: $black;
          background: #E0E0E0;
        }
        &.green{
          background: #1DDDA9;
        }
        &.primary {
            color: white;
            background-color: var(--red);

            &:hover {
                background-color: darken(#FF0000, 10%);
            }

            &.inverted {
                background: transparent;
                color: var(--blue);
                border: 1px solid var(--blue);

                &:hover {
                    background-color: var(--blue);
                    color: white;
                }
            }
        }
        &.widget {
            color: white;
            background-color: red;
            font-size: 12px;
            text-align: center;
            min-width: 250px;
            text-decoration: none;
            line-height: 12px;
            &:hover {
                opacity: 20px;
            }

        }

         &.secondary {
            color: var(--red);
            background-color: white;
            border: 1px solid var(--red);

            &:hover {
                background-color: transparentize(#FF0000, .9)
            }
        }
        
        .icon{
            min-width: var(--unit-l);
            min-height: var(--unit-l);
            width: var(--unit-l);
            height: var(--unit-l);

            margin-right: var(--unit-xs);

            background-color: rgba(255, 255, 255, 0.24);
            background-size: var(--unit);
            background-position: center;
            background-repeat: no-repeat;

            border-radius: 100%;
        }

        &[disabled], &.disabled {
            cursor: initial;
            background-color: #555;
            filter: grayscale(100%);

            &:hover {
                background-color: #555;
            }
        }

        cursor: pointer;
    }
</style>

<script>
export default {
    name: 'Button',
    props: {
        theme: String,
        route: String || null,
        routeName: String || null,
        href: String || null,
        hasIcon: {
            type: Boolean,
            default: false
        },
        submit: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasClickHandler() {
            return this.$listeners && this.$listeners.onClick;
        }
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
}
</script>
