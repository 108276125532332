<template>
  <section class="section-heading" v-if="title">
    <div class="holder container">
      <div class="heading-wrap">
        <h1 v-if="title">{{ title }}</h1>
        <p v-if="introText">{{ introText }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      default: 'Default Title'
    },
    introText: {
      type: String,
      default: 'Default Subtitle'
    }
  }
}
</script>

<style lang="scss" scoped>
.section-heading {
  color: white;
  background: $purple;
  .heading-wrap {
    max-width: 1200px;
    margin: 0 auto;
    padding: 48px 16px 0 16px;
    padding-bottom: 24px;
    text-align: center;
    @include tablet-up {
      padding: 64px 16px 0 16px;
    }
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 12px;
      @include tablet-up {
        font-size: 64px;
        line-height: 72px;
      }
    }
    p {
      font-size: 20px;
      line-height: 32px;
      @include tablet-up{
        font-size: 24px;
      }
    }
  }
}
</style>
