<template>
  <div class="preview-post" v-if="imageUrl || title || author">
    <div class="inner-box">
      <span class="title">Zo ziet je verhaal eruit</span>
        <article class="card">
          <div class="card-inner">
             <div class="card-image" :style="imageUrl ? {backgroundImage: `url(${imageUrl})`} : {}" v-if="imageUrl" />
              <div class="card-body">
                <h2 v-if="title">{{ title }}</h2>
                <p v-if="author">Door {{author}}</p>
                <p v-if="text" class="text">{{ text }}</p>
              </div>
            </div>
        </article>
    </div>
  </div>
</template>

<script>
  export default {
  name: 'FormPreview',
  props: {
    title: String,
    author:String,
    text: String,
    imageUrl: String,
  },

  }
</script>

<style lang="scss" scoped>
.preview-post{
  .inner-box{
    border-top: 1px solid #E0E0E0;
    .title{
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin: 24px 0;
    }
   .card{
    width: 100%;
    height: 100%;
      .card-inner{
        width: 100%;
        height: 100%;
        background: white;
        border: 1px solid rgba(18, 18, 18, 0.1);
        border-top: none;
        border-radius: 16px;
        box-shadow: 0px 8px 24px rgba(18, 18, 18, 0.1);
        overflow: hidden;
        .card-image{
          width: 100%;
          height: 100%;
          max-height: 418px;
          margin: 0;
          aspect-ratio: 16/9;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .card-body{
          padding: 24px;
          @include tablet-down{
            padding: 16px;
          }
          h2{
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            white-space: normal; /* Use normal white-space handling */
            margin-bottom: 25px;
            @include tablet-down{
              font-size: 16px;
              line-height: 24px;
            }
          }
          p{
            font-size: 16px;
            line-height: 24px;
            color: #616161;
            @include tablet-down{
              font-size: 12px;
              line-height: 20px;
            }
          }
          .text{
            color:black;
            white-space: pre-wrap;
            margin-top: 25px;
          }
        }
      }
    }
  }
}
</style>